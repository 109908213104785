<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <router-view />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  data() {
    return {
      data: null,
    }
  },
  created(){
    this.fetchData()
  },
  methods: {
    fetchData(){
        this.$http.get('application').then(res=>{
          this.data = res.data.data
          this.$store.commit('appConfig/UPDATE_APP', {
            appName: this.getConfigData('appName'),
            appLogoImage: this.getConfigData('appLogoImage'),
          })
        })
    },
    getConfigData(config){
      for (let index = 0; index < this.data.length; index++) {
        const configData = this.data[index];
        if (configData.config === config){
          return configData.data_url ? configData.data_url : configData.data
        }
      }
    },
  },
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
}
</script>
